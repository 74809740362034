import React, { useState } from 'react';
import { navigate } from 'gatsby';

import SEO from '../../components/seo';
import Layout from '../../components/layout';
import VideoCard from '../../components/videoCard';
import colors from '../../config/color';

const VideoDemo = ({ location }) => {
  return (
    <Layout
      showHamburgerBtn={false}
      showAccountBtn={false}
      showHeader={true}
      contentStyle={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // backgroundImage: `url("/images/background.jpg")`,
      }}
    >
      <SEO title="試聽內容" />
      <div>
        <h2>助眠</h2>
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/1.mp4"
          title="1. 與放鬆 α 波共振 - 聚氣如溪"
          text="經工研院實證研究音樂專輯，5 分鐘內自律神經系統高度協調。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/1.jpg"
        />
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/2.mp4"
          title="2. 與放鬆 α 波共振 - 身如雲"
          text="經工研院實證研究音樂專輯，10 分鐘內有效提升放鬆 α 波 21%。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/2.jpg"
        />
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/3.mp4"
          title="3. 與放鬆 α 波共振 - 生機之門"
          text="經工研院實證研究音樂專輯，適用肌肉緊繃、胸口易悶者。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/3.jpg"
        />
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/4.mp4"
          title="4. 與放鬆 α 波共振 - 百脈歸海"
          text="經工研院實證研究音樂專輯，讓旋律啟動大腦深層放鬆的禪修波動。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/4.jpg"
        />
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/5.mp4"
          title="5. 與放鬆 α 波共振 - 飄香"
          text="經工研院實證研究音樂專輯，抑制破壞性壓力荷爾蒙，提升專注力。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/5.jpg"
        />
        <hr />
        <h2>專注</h2>
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/6.mp4"
          title="6. 青春發電機 - 遇見天空"
          text="台灣第一套實證研究音樂專輯，提升心跳動力節奏，提振精神。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/6.jpg"
        />
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/7.mp4"
          title="7. 青春發電機 - 離開台北去旅行"
          text="台灣第一套實證研究音樂專輯，適用早上起床不想動、缺乏活力。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/7.jpg"
        />
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/8.mp4"
          title="8. 青春發電機 - 鯨豚嬉游記"
          text="台灣第一套實證研究音樂專輯，適用頭昏腦脹、精神不振者。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/8.jpg"
        />
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/9.mp4"
          title="9. 青春發電機 - 嘟嘟霧"
          text="台灣第一套實證研究音樂專輯，提升創造、思考，刺激右腦活動。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/9.jpg"
        />
        <VideoCard
          src="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/1.mp4"
          title="10. 青春發電機 - 冬日鸕鶿之舞（動力版）"
          text="台灣第一套實證研究音樂專輯，強化大腦資訊吸收能力、專注力。"
          img="https://bamboodd-public.s3-ap-southeast-1.amazonaws.com/bamboodd-site/demoVideo/imgCover/10.jpg"
        />
      </div>

      <style jsx>{`
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 30px;
        }

        h2 {
          color: ${colors['purple']};
          font-family: NotoSansCJKtc-Regular;
          line-height: 20px;
          margin: 15px 0 15px 0;
          font-size: 36px;
        }

        hr {
          margin: 40px 0;
          width: 100%;
          border: 0;
          height: 1px;
          background: #333;
          background-image: linear-gradient(to right, #ccc, #333, #ccc);
        }

        @media (min-width: 768px) {
          div {
            padding: 60px;
          }
        }
      `}</style>
    </Layout>
  );
};

export default VideoDemo;
