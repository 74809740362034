import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import colors from '../config/color';
import { FaRegPlayCircle } from 'react-icons/fa';

const VideoCard = props => {
  const { src, title, text, img } = props;
  const [isClickPlay, setClickPlay] = useState(false);
  let videoTag = null;

  return (
    <div className="content">
      {isClickPlay ? null : (
        <div
          onClick={() => {
            if (videoTag) {
              videoTag.play();
              setClickPlay(true);
            }
          }}
        >
          <div className="cover-img">
            <div className="player-icon">
              <FaRegPlayCircle style={{ height: 60, width: 60 }} />
            </div>
            <img src={img} />
          </div>
        </div>
      )}
      <video
        ref={video => {
          videoTag = video;
        }}
        controls
        src={src}
      />
      <div className="text-content">
        <h2 className="text-sty">{title}</h2>
        <p className="text-sty">{text}</p>
      </div>

      <style jsx>{`
        video {
          display: ${isClickPlay ? 'unset' : 'none'};
        }
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 30px;
        }

        .cover-img {
          position: relative;
          display: flex;
          justify-content: center;
        }

        .player-icon {
          position: absolute;
          top: calc(50% - 30px);
          left: calc(50% - 30px);
          z-index: 10;
        }

        img {
          z-index: 5;
          width: 100%;
          height: 100%;
        }

        .text-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        }

        video {
          width: 100%;
        }

        .text-sty {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          line-height: 26px;
        }

        h2 {
          margin: 15px 0 0 0;
          font-size: 24px;
        }

        p {
          margin: 15px 0 15px 0;
          font-size: 18px;
        }

        @media (min-width: 768px) {
          video {
            max-width: 800px;
          }

          img {
            max-width: 720px;
          }

          .text-content {
            max-width: 800px;
          }
        }
      `}</style>
    </div>
  );
};

VideoCard.propTypes = {
  slug: PropTypes.string,
  text: PropTypes.string,
};

VideoCard.defaultProps = {
  slug: '',
  text: '',
};

export default VideoCard;
